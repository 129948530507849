import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import GPT_GUARD_LOGO from "../../assets/gpt_guard_logo.svg";
import { CopyRegular } from "@fluentui/react-icons";
import { Dialog, Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { HistoryButton, ShareButton } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import { PostgresDBStatus } from "../../api";
import { SignOutButton } from "../auth/SignOutButton";
import { IS_DB_AVAILABLE } from "../../api/authConfig";

const Layout = () => {
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy URL");
    const appStateContext = useContext(AppStateContext);

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => { }, [appStateContext?.state.isPostgresDBAvailable.status]);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                {/* <Stack horizontal verticalAlign="center" horizontalAlign="space-between"
                    className={styles.headerContainer}
                >
                    <Stack horizontal verticalAlign="center">
                        <img
                            src={GPT_GUARD_LOGO}
                            className={styles.headerIcon}
                            aria-hidden="true"
                        />
                        <Link to="www.gptguard.ai" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}></h1>
                        </Link>
                    </Stack> */}
                    {/* <Stack horizontal tokens={{ childrenGap: 4 }} verticalAlign="center">
                        {(appStateContext?.state.isPostgresDBAvailable?.status !== PostgresDBStatus.NotConfigured) &&
                            <HistoryButton onClick={handleHistoryClick} text={appStateContext?.state?.isChatHistoryOpen ? "Hide chat history" : "Show chat history"} />
                        }
                        <ShareButton onClick={handleShareClick} />
                        {IS_DB_AVAILABLE ? <SignOutButton></SignOutButton> : null}
                    </Stack> */}
                {/* </Stack> */}
            </header>
            <Outlet />
            {/* <Dialog
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: "Share the web app",
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
                    <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                </Stack>
            </Dialog> */}
        </div>
    );
};

export default Layout;
