import { VITE_AUTHORITY } from "./env";

export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_CLIENT_ID,
    authority: VITE_AUTHORITY
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.read", "openid", "profile", "offline_access"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

const DB_VALUE = import.meta.env.VITE_POSTGRES_DB_AVAILABLE;
const DB = DB_VALUE && DB_VALUE.length !== 0 && DB_VALUE !== undefined && DB_VALUE !== null ? DB_VALUE.toLowerCase() : null
export const IS_DB_AVAILABLE = DB !== null && DB === "true" ? true : false

const IS_MASK_CONTENT = import.meta.env.VITE_IS_MASK_VISIBLE;
const MASK_CONTENT = IS_MASK_CONTENT && IS_MASK_CONTENT.length !== 0 && IS_MASK_CONTENT !== undefined && IS_MASK_CONTENT !== null ? IS_MASK_CONTENT.toLowerCase() : null
export const IS_MASK_VISIBLE = MASK_CONTENT !== null && MASK_CONTENT === "true" ? true : false
