import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, HashRouter, Routes, Route, Outlet } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";
import styles from "./pages/sidenav/SideNav.module.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { AppStateProvider } from "./state/AppProvider";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import Microsoft from "./pages/auth/Microsoft";
import { PublicClientApplication } from "@azure/msal-browser";
import { IS_DB_AVAILABLE, msalConfig } from "./api/authConfig";
import localStorageService from "./api/api";
import SideNav from "./pages/sidenav/SideNav";
import Navbar from "./pages/sidenav/Navbar";
import Public from "./pages/public/Public";

initializeIcons();

export const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {

    const GA_ID = import.meta.env.VITE_GA_ID;
    const GTM_ID = import.meta.env.VITE_GTM_ID;
    localStorageService.setItem('GA_ID', GA_ID);
    localStorageService.setItem('GTM_ID', GTM_ID);

    const { instance } = useMsal();

    const handleLoginFailed = () => {
        handleLogout();
    };

    const handleLogout = () => {
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });
        localStorageService.removeItem('accesstoken');
    }

    return (
        <AppStateProvider>
            {/* <Router> */}
                {/* {IS_DB_AVAILABLE ? (<><UnauthenticatedTemplate>
                    <Routes>
                        <Route
                            index
                            path="/"
                            element={
                                <Microsoft onLoginFailed={handleLoginFailed} />
                            }
                        />
                    </Routes>
                </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Chat />} />
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </AuthenticatedTemplate>
                </>
                ) : ( */}
                  <Router>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<SideNav />}>
                            <Route index element={<Chat />} />
                            <Route path="no-filter" element={<Public />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Route>
                </Routes>

            </Router>


                {/* <Router>
                <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <Navbar />
                    </div>
                    <div className={styles.rightContainer}>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route path="/"  element={<Chat/>} />
                            <Route path="/no-filter" element={<Public/>} />
                            </Route>
                    </Routes>
                    </div>
                    </div>
                </Router> */}

                {/* )} */}

        </AppStateProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
);


