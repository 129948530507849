import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Stack, Text } from "@fluentui/react";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'

import eyeIcon from "../../assets/eye-icon.svg";
import eyeOffIcon from "../../assets/eye-off.svg";


interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
    description?: {
        key: string,
        text: string
    }
    maskedAssistantContent?: boolean;
    onAssistantMessageClick : () => void;
    onAssistantMaskClick:()=>void;
    isEyeEnabled:boolean | null;
}

export const Answer = ({
    answer,
    maskedAssistantContent,
    onCitationClicked,
    description,
    onAssistantMessageClick: onAssistantMsgClick,
    onAssistantMaskClick: onAssistantMaskClick,
    isEyeEnabled
}: Props) => {
    const checkEyeEnable = isEyeEnabled === null || isEyeEnabled === false ? false: true ;
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const filePathTruncationLimit = 50;

    const parsedAnswer = useMemo(() => parseAnswer(answer as any), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);

    const isValidAssistantContent = maskedAssistantContent !== undefined && maskedAssistantContent !== null;
    const isMaskedAssistantAvailable = checkEyeEnable && isValidAssistantContent && maskedAssistantContent === false && !React.isValidElement(maskedAssistantContent);
    const isMaskedAssistantReactElementAvailable =  checkEyeEnable && maskedAssistantContent && maskedAssistantContent !== undefined && maskedAssistantContent !== null && React.isValidElement(maskedAssistantContent)   

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    const handleAssistantMsgClick = () => {
        onAssistantMsgClick()
    };

    const handleAssistantMaskedContentClick = () => {
        onAssistantMaskClick()
    };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath && citation.chunk_id) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }

    const handleDescription = () => {
        if (description && description.key === 'mask') {
            return true
        }
        return false
    }

    return (
        <>
            <Stack className={!isMaskedAssistantAvailable && React.isValidElement(maskedAssistantContent)  ? `${styles.answerContainerFlip}` : `${styles.answerContainer}`} tabIndex={0}>
                <div  className={`${styles.iconContainer}`}>
                <div className={styles.answerHeaderText}>{!isMaskedAssistantAvailable && React.isValidElement(maskedAssistantContent) ? "Masked Prompt Received from ChatGPT ":"Secured results by GPT Guard"}</div>
                <div className={styles.eyeIcon}><img src={isMaskedAssistantAvailable ? eyeIcon :  React.isValidElement(maskedAssistantContent) ?  eyeOffIcon : ''}
                    onClick={maskedAssistantContent !== undefined && maskedAssistantContent !== null ?  maskedAssistantContent === false ? handleAssistantMsgClick :  React.isValidElement(maskedAssistantContent) ?  handleAssistantMaskedContentClick : ()=>{} : ()=>{}}/></div>
                </div>
                {
                      isMaskedAssistantReactElementAvailable ? maskedAssistantContent as any : <Stack.Item grow>
                      <ReactMarkdown
                          linkTarget="_blank"
                          remarkPlugins={[remarkGfm, supersub]}
                          children={parsedAnswer.markdownFormatText}
                          className={handleDescription() ? styles.maskText : styles.answerText}
                      />
                  </Stack.Item>
                }
                <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}
                        >
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " references" : "1 reference"}</span>
                                    </Text>
                                    <FontIcon className={styles.accordionIcon}
                                        onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                    />
                                </Stack>

                            </Stack>
                        </Stack.Item>
                    )}
                    <Stack.Item className={styles.answerDisclaimerContainer}>
                        <span className={styles.answerDisclaimer}> {!isMaskedAssistantAvailable && React.isValidElement(maskedAssistantContent) ? null : "AI-generated content may be incorrect "} </span>
                    </Stack.Item>
                </Stack>
                {chevronIsExpanded &&
                    <div style={{ marginTop: 8, display: "flex", flexFlow: "wrap column", maxHeight: "150px", gap: "4px" }}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }
            </Stack>
        </>
    );
};
